import React, { useState } from "react";
import { RightFootProfileImg } from "../../../assets";
import { IDiagnosis } from "../../../@types/diagnosis.interface";
import "./rightfeetProfile.style.css";

const RightfeetProfile = ({ footcareAnalysis }: { footcareAnalysis: IDiagnosis }) => {
	const isOnReportPage = window.location.pathname.includes("/reports");
	console.log("===>right feet", footcareAnalysis?.right_feet_pain_point_list);
	const [activeDiagnosis, setActiveDiagnosis] = useState<{ painPoint: number } | null>(null);
	return (
		<div className="right-foot-profile">
			<div className="container">
				<div className="h-full image-container">
					<img
						src={RightFootProfileImg}
						alt="Right Feet Image"
						style={isOnReportPage ? { height: "20vw" } : { height: "14vw" }}
						className="profile-img"
					/>
					<div className="arch-type">{footcareAnalysis?.right_feet_arc_type}</div>
					{footcareAnalysis?.right_feet_pain_point_list.includes(3) ? (
						<div
							className={`profile-3 profile-dot ${
								activeDiagnosis?.painPoint === 3 ? "pain-point-item-selected-profile" : ""
							}`}
						/>
					) : null}
					{footcareAnalysis?.right_feet_pain_point_list.includes(2) ? (
						<div
							className={`profile-2 profile-dot ${
								activeDiagnosis?.painPoint === 2 ? "pain-point-item-selected-profile" : ""
							}`}
						/>
					) : null}
					{footcareAnalysis?.right_feet_pain_point_list.includes(1) ? (
						<div
							className={`profile-1 profile-dot ${
								activeDiagnosis?.painPoint === 1 ? "pain-point-item-selected-profile" : ""
							}`}
						/>
					) : null}
					{footcareAnalysis?.right_feet_pain_point_list.includes(4) ? (
						<div
							className={`profile-4 profile-dot ${
								activeDiagnosis?.painPoint === 4 ? "pain-point-item-selected-profile" : ""
							}`}
						/>
					) : null}
					{footcareAnalysis?.right_feet_pain_point_list.includes(7) ? (
						<div
							className={`profile-7 profile-dot ${
								activeDiagnosis?.painPoint === 7 ? "pain-point-item-selected-profile" : ""
							}`}
						/>
					) : null}
					{footcareAnalysis?.right_feet_pain_point_list.includes(8) ? (
						<div
							className={`profile-8 profile-dot ${
								activeDiagnosis?.painPoint === 8 ? "pain-point-item-selected-profile" : ""
							}`}
						/>
					) : null}
					{footcareAnalysis?.right_feet_pain_point_list.includes(6) ? (
						<div
							className={`profile-6 profile-dot ${
								activeDiagnosis?.painPoint === 6 ? "pain-point-item-selected-profile" : ""
							}`}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default RightfeetProfile;
