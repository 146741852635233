import React from "react";
import { defaultImage, footProfile, leftFeetProfile } from "../../../../../../../../assets";
import { Grid } from "@material-ui/core";
import KnowMoreButton from "../knowMoreButton/KnowMoreButton";
import LeftfeetProfile from "../../../../../../../shared/leftFeetProfile/LeftfeetProfile";
import ConnectedList from "../../../../../../../shared/connectedList/ConnectedList";
import { IDiagnosis } from "../../../../../../../../@types/diagnosis.interface";

const LeftPainPointDetails = ({ footcareAnalysis }: { footcareAnalysis: IDiagnosis }) => {
	return (
		<div className="leftPainPointDetails painpointDetailsDivision">
			<h2 style={{ marginBottom: 10 }}>Left Feet</h2>
			<Grid container spacing={1} alignItems="center">
				<Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
					<div className="footDisease">
						<div className="disease-details">
							<h2 style={{ marginBottom: 18 }}>Detected Disease</h2>
							<ConnectedList
								items={footcareAnalysis?.left_feet_diagnosis_name_list}
								key="left_feet_name"
							/>
						</div>
					</div>
				</Grid>
				<Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
					{/* <img src={painPointImage || defaultImage} alt="left_feet_profile" /> */}
					{footcareAnalysis ? <LeftfeetProfile footcareAnalysis={footcareAnalysis} /> : null}
				</Grid>
			</Grid>
		</div>
	);
};

export default LeftPainPointDetails;
